import { UnderDev } from "../../components/UnderDev";

export const IndexZonalOps = () => {

  return (
    <div>
      <h1>Index Zonal Managament</h1>
      <UnderDev />
    </div>
  );
};