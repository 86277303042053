import { useState, useEffect } from "react";
import {UnderDev} from "../../../../components/UnderDev"

export const OfflineOrderPOS = ({}) => {
  return (
    <div>
      <UnderDev />
    </div>
  );
};
