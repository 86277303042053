import { UnderDev } from "../../../components/UnderDev";

export const IndexPCSupportManagement = ({}) => {
  return (
    <div>
      <h2>Index PC Support Management</h2>
      <UnderDev />
    </div>
  );
};
