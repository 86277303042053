import { UnderDev } from "../../components/UnderDev";
import { Adminoptions } from "./Adminoptions";

export const IndexAdminhome = () => {
  return (
    <div>
     <Adminoptions/>
     <UnderDev />
    </div>
  );
};
