import { UnderDev } from "../../components/UnderDev";

export const IndexBzDevTask = ({
 
}) => {  

  return (
    <div>
      <UnderDev/>
    </div>
  );
};